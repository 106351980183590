import PropTypes from 'prop-types';

import configResolver from './shared-app-config';

import App from 'core/components/App';
import BaseRoutes from 'core/components/BaseRoutes';
import bindPropsHOC from 'core/components/bindProps';

import Layout from 'site/components/Layout';
import Comments from 'site/pages/comments';
import { Mobile, Desktop } from 'core/components/breakpoint';

import LegalPage from 'core/components/LegalPage';
import RulesRecommenders from 'core/components/RulesRecommenders';

import {
  Fullscreen as DesktopFullscreen,
} from './components/Ads/desktop';

import GlobalNativeStyles from './components/Ads/styles/global';

import {
  Rich,
  Fullscreen as MobileFullscreen,
} from './components/Ads/mobile';

import Main from './pages/main';
import Search from './pages/search';
import Topic from 'site/pages/topic';
import About from 'site/pages/about';
import Author from 'site/pages/author';
import Authors from 'site/pages/authors';
import { Rubric, Tag } from './pages/section';
import Profile from './pages/profile';
import LeaderBoard from './pages/leaderBoard';

export default function Site(props, context) {
  /**
   * Конфиг приложения нужно резолвить на каждую перерисовку чтоб при SSR
   * правильно резолвились переменные окружения.
   */
  const config = configResolver(context.requestHeaders);

  return (
    <App {...config}>
      <Layout>
        <BaseRoutes
          tags={Tag}
          main={Main}
          topic={Topic}
          about={About}
          search={Search}
          rubrics={Rubric}
          author={Author}
          authors={Authors}
          profile={Profile}
          comments={Comments}
          legal={LegalPage}
          leaderBoard={LeaderBoard}
          rulesRecommenders={bindPropsHOC({ mail: 'info@moslenta.su' })(RulesRecommenders)}
        />
      </Layout>
      <Desktop>
        <DesktopFullscreen />
        <GlobalNativeStyles />
      </Desktop>
      <Mobile>
        <Rich />
        <MobileFullscreen />
      </Mobile>
    </App>
  );
}

Site.contextTypes = {
  requestHeaders: PropTypes.shape({
    host: PropTypes.string,
  }),
};
